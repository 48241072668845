<template>
  <section class="section-container">
    <div class="container-div align-center d-flex">
      <v-row class="signin">
        <v-col cols="6" class="left">
          <img src="../../assets/logo/Login_Logo.png" height="600" class="mt-2" />
        </v-col>
        <v-col cols="6" class="right">
          <h2></h2>
          <validation-observer ref="observer">
            <v-form @submit.prevent="submit">
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required"
              >
                <v-text-field
                  v-model="email"
                  :error-messages="errors"
                  label="Email"
                  required
                  outlined
                  dark
                  filled
                  dense
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Password"
                rules="required"
              >
                <v-text-field
                  v-model="password"
                  :error-messages="errors"
                  label="Password"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPass = !showPass"
                  required
                  outlined
                  dense
                  dark
                  filled
                  :type="showPass ? 'text' : 'password'"
                ></v-text-field>
              </validation-provider>
              <v-checkbox
                label="Remember me"
                dark
                class="pa-0 px-1 ma-0"
                v-model="rememberMe"
              ></v-checkbox>
              <div class="text-center">
                <v-btn
                  class="signin-btn"
                  type="submit"
                  rounded
                  color="white"
                  dark
                >
                  LOGIN
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-col>
      </v-row>
      <snackbar
        :text="snackbarText"
        :showSnackbar="isShowSnackbar"
        @hide="isShowSnackbar = false"
      ></snackbar>
    </div>
  </section>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import axios from "axios";
import Snackbar from "../Snackbar.vue";
import VueCookies from 'vue-cookies';
import {
  extend,
  ValidationProvider,
  setInteractionMode,
  ValidationObserver,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "Login",
  components: {
    ValidationProvider,
    ValidationObserver,
    Snackbar,
  },
  data: () => ({
    email: "",
    password: "",
    showPass: false,
    snackbarText:'',
    isShowSnackbar:false,
    rememberMe:false
  }),
  computed: {
  },
  mounted: function () {
    this.valueSet()
  },
  methods: {
    async submit() {
      const valid = await this.$refs.observer.validate();
      if (valid) {
        this.login();
      }
    },
    login: function () {
      var loginData = {username : this.email, password : this.password};
      axios.post(this.Node_JS_HOST + "/api/v1/web/auth/logIn",loginData)
      .then((res) => {
          if(res){
            const response = res.data;
            localStorage.setItem('token', response.data.token);
            this.isRemember();
            this.$router.push('/pi-ads');
          }
      })
      .catch((err) => {
          if(err.response.data.error === "Not Found"){
            this.snackbarText = 'Invaild email and Password.';
            this.isShowSnackbar = true;
          }else{
            this.snackbarText = 'Something went wrong please try again';
            this.isShowSnackbar = true;
          }
      });
    },
    isRemember: function(){
      if(this.rememberMe == true){
        VueCookies.config('7d');
        VueCookies.set('email', this.email);
        VueCookies.set('password', this.password);
        VueCookies.set('rememberMe', this.rememberMe);
      }else{
        VueCookies.remove("email");
        VueCookies.remove("password");
        VueCookies.remove("rememberMe");
      }
    },
    valueSet:function(){
      this.email = VueCookies.get('email');
      this.password = VueCookies.get('password');
      this.rememberMe = VueCookies.get('rememberMe');
    }
  },
};
</script>

<style lang="scss">
.container-div {
  background: rgba(204, 204, 204, 0.7);
  width: 100%;
  height: 100%;
}
.section-container {
  background-image: #1976d2;
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  .signin {
    padding: 0;
    max-width: 1000px;
    margin: 0 auto;
    min-height: 600px;
    box-shadow: 0 0 1px 1px rgba($color: #000000, $alpha: 0.1);
    .left {
      padding: 30px;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      display: flex;
      color: #1976d2;
      background-color: #f9f9f9;
    }
    .right {
      padding: 30px;
      box-sizing: border-box;
      background: #1976d2;
      color: #fff;
      justify-content: center;
      align-items: center;
      display: flex;
      h2 {
        text-align: center;
        margin: 30px 0;
      }
      .signin-btn {
        width: 100%;
        color: #1976d2;
      }
    }
  }
}
</style>
